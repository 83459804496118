<div
  *ngIf="parentFormGroup &&
          parentFormGroup.controls &&
          parentFormGroup.controls[this.controlName]"
>
  <mat-form-field
    [formGroup]="parentFormGroup"
    appearance="outline"
    class="form-field date-picker"
  >
    <input
      #input
      [autocomplete]="false"
      [formControlName]="controlName"
      [matDatetimepicker]="dts"
      [max]="max"
      [min]="min"
      [placeholder]="placeholder"
      date
      matInput
    >
    <mat-datetimepicker-toggle [for]="dts" matSuffix></mat-datetimepicker-toggle>
    <mat-datetimepicker #dts openOnFocus="false" timeInterval="5" type="date"></mat-datetimepicker>
  </mat-form-field>
  <div
    *ngIf="error"
    class="form-field-error"
    fxLayoutAlign="start end"
  >
    {{error}}
  </div>
</div>
