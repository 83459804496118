<div
  class="basic-dialog"
>
  <div
    class="close-icon-panel"
    fxLayoutAlign="center center"
  >
    <mat-icon (click)="closeDialog()" class="close-icon">close</mat-icon>
  </div>

  <div mat-dialog-content>
    <template #anchor></template>
  </div>
</div>
