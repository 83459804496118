<div
  *ngIf="parentFormGroup &&
          parentFormGroup.controls &&
          parentFormGroup.controls[this.controlName]"
>
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <mat-form-field
      [formGroup]="parentFormGroup"
      [ngClass]="{
        'enabled-clear-selection': enableClearSelection &&
                                   parentFormGroup.controls[this.controlName].value &&
                                   parentFormGroup.controls[this.controlName].status !== 'DISABLED',
        'has-value': (parentFormGroup.controls[this.controlName].status !== 'DISABLED') &&
            (enableClearSelection && parentFormGroup.getRawValue()[controlName])
      }"
      appearance="outline"
      class="form-field"
      hideRequiredMarker
    >
      <mat-select
        #select
        [formControlName]="controlName"
        [multiple]="enableMultiple"
        [placeholder]="placeholder"
      >
        <mat-option *ngFor="let option of options" [value]="option.key" [disabled]="option.disabled" class="single-select-option ja-heading">
          {{option.value}}
        </mat-option>
      </mat-select>
      <mat-icon
        (click)="clearSelection($event)"
        *ngIf="(parentFormGroup.controls[this.controlName].status !== 'DISABLED') &&
            (enableClearSelection && parentFormGroup.getRawValue()[controlName])"
        class="clear-selection form-field-icon suffix-icon"
        matSuffix
        matTooltip="Clear"
      >close
      </mat-icon>
    </mat-form-field>
  </div>
  <div
    *ngIf="error"
    class="form-field-error"
    fxLayoutAlign="start end"
  >
    {{error}}
  </div>
</div>
