/* NgRx */
import {createAction, props} from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[order list page] clear state';
export const ClearState = createAction(CLEAR_STATE);

/******** SET ORDERS LIST FILTER ***********/
const SET_ORDERS_LIST_FILTER = '[order list page] Set Orders list filter';
export const SetOrdersListFilter = createAction(SET_ORDERS_LIST_FILTER, props<any>());

/******** ORDER SUMMARY COUNT REQUEST *********/
const ORDER_SUMMARY_COUNT_REQUEST = '[order list page] order summary count request';
export const OrderSummaryCountRequest = createAction(ORDER_SUMMARY_COUNT_REQUEST, props<any>());

const ORDER_SUMMARY_COUNT_SUCCESS = '[order list page] order summary count success';
export const OrderSummaryCountSuccess = createAction(ORDER_SUMMARY_COUNT_SUCCESS, props<any>());

const ORDER_SUMMARY_COUNT_FAILURE = '[order list page] order summary count failure';
export const OrderSummaryCountFailure = createAction(ORDER_SUMMARY_COUNT_FAILURE, props<any>());

/******** FETCH ORDERS LIST ***********/
const FETCH_ORDERS_LIST_REQUEST = '[order list page] fetch orders list requested';
export const FetchOrdersListRequest = createAction(FETCH_ORDERS_LIST_REQUEST, props<any>());

const FETCH_ORDERS_LIST_SUCCESS = '[order list page] fetch orders list successful';
export const FetchOrdersListSuccess = createAction(FETCH_ORDERS_LIST_SUCCESS, props<any>());

const FETCH_ORDERS_LIST_FAILURE = '[order list page] fetch orders list failure';
export const FetchOrdersListFailure = createAction(FETCH_ORDERS_LIST_FAILURE, props<any>());

/******** SEND STATUS NOTIFICATION ***********/
const SEND_STATUS_NOTIFICATION_REQUEST = '[order list page] send status notification requested';
export const SendStatusNotificationRequest = createAction(SEND_STATUS_NOTIFICATION_REQUEST, props<any>());

const SEND_STATUS_NOTIFICATION_SUCCESS = '[order list page] send status notification successful';
export const SendStatusNotificationSuccess = createAction(SEND_STATUS_NOTIFICATION_SUCCESS, props<any>());

const SEND_STATUS_NOTIFICATION_FAILURE = '[order list page] send status notification failure';
export const SendStatusNotificationFailure = createAction(SEND_STATUS_NOTIFICATION_FAILURE, props<any>());

/******** FETCH EMAIL STATUS ***********/
const FETCH_EMAIL_STATUS_REQUEST = '[order list page] fetch email status requested';
export const FetchEmailStatusRequest = createAction(FETCH_EMAIL_STATUS_REQUEST, props<any>());

const FETCH_EMAIL_STATUS_SUCCESS = '[order list page] fetch email status successful';
export const FetchEmailStatusSuccess = createAction(FETCH_EMAIL_STATUS_SUCCESS, props<any>());

const FETCH_EMAIL_STATUS_FAILURE = '[order list page] fetch email status failure';
export const FetchEmailStatusFailure = createAction(FETCH_EMAIL_STATUS_FAILURE, props<any>());

/*************** SEND EMAIL ****************/
const SEND_EMAIL_REQUEST = '[order list page] send email requested';
export const SendEmailRequest = createAction(SEND_EMAIL_REQUEST, props<any>());

const SEND_EMAIL_SUCCESS = '[order list page] send email successful';
export const SendEmailSuccess = createAction(SEND_EMAIL_SUCCESS, props<any>());

const SEND_EMAIL_FAILURE = '[order list page] send email failure';
export const SendEmailFailure = createAction(SEND_EMAIL_FAILURE, props<any>());